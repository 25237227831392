import React from "react";

function LoadingCircle() {
  // implement by https://cssloaders.github.io/
  return (
    <span className="loader mrg-tb-auto"></span>
  )
}

export default LoadingCircle
